import logoHeader from "../../images/current-calls/current_calls_bg_3.svg";
import ContactUsCurrentCalls from "../../components/ContactUsCurrentCalls";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import node_circle from '../../images/about/node_circle.svg';
import submission from "../../images/grants/submission.svg";
import Newsletter from "../../components/Newsletter";
import scope from "../../images/grants/scope.svg";
import forms from "../../images/grants/forms.svg";
import faqs from "../../images/grants/faqs.svg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./CurrentCallsPage.css";

export default function CurrentCallsPage() {
    return (
        <div className="page-container grants">
            <Header className="header-grants-oc" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Open Calls</div>
                    <div className="title-title">Current call</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / Open Calls / Current Calls
                </div>
            </div>
            <div id="content">
                <div id="header">
                    <div className="content">
                        <div className="schema">
                            <div className="body">
                                <div className="title">
                                    4th REINFORCING Open Call
                                </div>
                                <div className="subtitle">
                                    Booster call
                                </div>
                                <div className="text">
                                    Applicants can submit their proposal by <b>30th January 2025</b> at <b>17:00</b> (Brussels time).<br></br>
                                    This ORRI Booster call will support <b>organizations</b> implementing an <b>8-months projects</b>.
                                </div>
                                <div className="text">
                                    On December 12th REINFORCING hosted a webinar in which we disclosed the insights,
                                    and guided participants through the application process. You can find the webinar
                                    recording <b><a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=7l_WTSEnHkM">here</a></b>.
                                </div>
                            </div>
                            <div className="logo">
                                <div className="background">
                                    <img src={logoHeader} alt="reinforcing" />
                                </div>
                                <div className="logo-title">
                                    The 4th REINFORCING Open Call (Booster Call) <br/> <span className="highlighted">is now open!</span>
                                </div>
                                <a href="/open-calls/open-calls/booster">
                                    Apply
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="current-calls-objectives">
                    <div className="content">
                        <div className="title">
                            Topic of the call
                        </div>
                        <div className="text">
                            <b>
                                This call supports organizations and territories to experiment with
                                ORRI approaches around the topic of “Responsible Digitalization”
                                and implement actions to open their research and innovation processes
                                and align them to societal needs and expectations. 
                            </b>
                        </div>
                        <div className="text2">
                            The Full Call Text document and the EU Funding & Tenders Portal provide
                            more details on the specific challenges and the expected outcomes of the Call.
                        </div>
                        <a className="full-call-text" href="/4th REINFORCING Open Call.pdf" target="_blank">
                            Full call text
                        </a>
                        <div className="objective-button">
                            <a target="_blank" href="https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/opportunities/competitive-calls-cs/9461" id="guide-link">
                                EU Funding & Tenders Portal  →
                            </a>
                        </div>
                    </div>
                </div>
                <div className="application-banner">
                    <div className="content">
                        <div className="open-calls-content">
                            <div className="open-calls-1">
                                <div id="application-1">
                                    <h1 id="application-section-2">
                                        Specific call requirements
                                    </h1>
                                    <h5 id="application-section-6">
                                        Applicants for this Booster Call are required to <b>have a track record
                                        in ORRI approaches and to describe their experience</b> in the field.
                                    </h5>
                                    <img id="application-img" src={node_circle} alt="node_circle" />
                                </div>
                            </div>
                            <div className="open-calls-2">
                                <div id="application-2">
                                    <h1 id="application-section-4">
                                        Who could apply
                                    </h1>
                                    <h5 id="application-section-5">
                                        <ul>
                                            <li>
                                                Applicants <b>established in an EU country (Member State or associated country)</b>,
                                                including their outermost regions or associated countries eligible to receive HEU
                                                grants, provided that the applicants are not covered by the Council sanctions in force.
                                            </li>
                                            <li>
                                                <b>Applicants must be legal entities</b> already established at the moment of the submission,
                                                such as, but not limited to, Civil Society Organizations (CSO), Non-Governmental Organizations
                                                (NGO), Higher Education Institutions, Research Centres and Small and Medium Enterprises (SME).
                                            </li>
                                            <li>
                                                Applicants must <b>declare their financial stability</b>.
                                            </li>
                                            <li>
                                                Applicants <b>shall not have any potential conflict of interest with the
                                                REINFORCING</b> selection process (notably they should not have legal
                                                relationship with any organization that is a member of the REINFORCING
                                                consortium, including affiliated entities).
                                            </li>
                                        </ul>
                                    </h5>
                                    <h5 id="application-section-5">
                                        Self-employed applicants can also apply as natural person
                                        if they are legal entities (see the third bullet point above).
                                    </h5>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div id="more-info">
                    <div className="title">
                        How to apply
                    </div>
                    <div className="content">
                        <div className="text">
                            When submitting their proposals, applicants are required to complete a <b>template</b> and upload it in 
                            the <b><a target="_blank" href="/open-calls/open-calls/incubators" id="submission-link">submission page</a></b>. No
                            further sections beyond those requested will be considered in the application. Proposals should not be
                            longer <b>than 5 pages</b> and applications exceeding the limits will not be considered.
                        </div>
                    </div>
                    <div className="elements-mobile">
                        <div className="content">
                            <div className="element">
                                <img src={scope} alt="Scope of the call" />
                                <div className="title">
                                    Scope of the call
                                </div>
                                <div className="text">
                                    Find the call text
                                </div>
                                <a href="/4th REINFORCING Open Call.pdf" download>
                                    <span id="information-buttons">
                                        More info
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={submission} alt="Submission Link" />
                                <div className="title" id="submission-title">
                                    Submission page
                                </div>
                                <div className="text">
                                    Access to the submission platform to apply
                                </div>
                                <a href="/open-calls/open-calls/booster ">
                                    <span id="information-buttons">
                                        Apply
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={forms} alt="Applicants Forms" />
                                <div className="title">
                                    Guidelines for Applicants
                                </div>
                                <div className="text">
                                    Download the Guide for Applicants
                                </div>
                                <a href="/Guidelines for applicants_updated.pdf" download>
                                    <span id="information-buttons">
                                        Download
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={faqs} alt="FAQs" />
                                <div className="title">
                                    FAQs
                                </div>
                                <div className="text">
                                    Find the answers to frequently asked questions
                                </div>
                                <a href="/FAQs_updated_11.2024.pdf" download>
                                    <span id="information-buttons">
                                        View faqs
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="call-calendar">
                    <div className="content">
                        <div className="title">
                            call calendar
                        </div>
                        <div className="dates">
                            <div className="title">
                                Important dates for the application process are described in the following table:
                            </div>
                            <ul id="dates-table">
                                <li>
                                    <div className="date">
                                        18th November 2024
                                    </div>
                                    <div className="text">
                                        Official launch of the call
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        30th January 2025
                                    </div>
                                    <div className="text">
                                        Deadline for proposal submission - 17:00 (Brussels time)
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        February/March 2025
                                    </div>
                                    <div className="text">
                                        Evaluation process
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        March/April 2025
                                    </div>
                                    <div className="text">
                                        Communication to projects selected for funding
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        1st May 2025
                                    </div>
                                    <div className="text">
                                        Start of the funded projects
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        31st December 2025
                                    </div>
                                    <div className="text">
                                        End of the funded projects
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUsCurrentCalls />
            <Newsletter />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}